import { graphql, PageProps } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Layout } from "../../components"
import { SummarizedAuthorProps } from "../../components/blog/SummarizedAuthor"
import { Tags } from "../../components/blog/Tags"
import { PostMetadata } from "../../components/blog/types"
import "./styles.css"

const LinkedInIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.5823 0H2.42054C2.10212 0.000543025 1.78693 0.0630614 1.49296 0.183987C1.19899 0.304913 0.932 0.481879 0.707234 0.704778C0.482468 0.927678 0.304326 1.19215 0.182981 1.48308C0.0616368 1.77402 -0.000535938 2.08573 1.448e-05 2.40041V21.601C-0.00108724 21.9159 0.0606909 22.228 0.181806 22.5193C0.302921 22.8107 0.480998 23.0756 0.705837 23.2988C0.930676 23.522 1.19786 23.6992 1.4921 23.8202C1.78633 23.9413 2.10183 24.0038 2.42054 24.0041H21.5823C21.9005 24.0036 22.2155 23.9411 22.5093 23.8202C22.8031 23.6994 23.07 23.5225 23.2946 23.2998C23.5192 23.077 23.6973 22.8127 23.8186 22.5219C23.9398 22.2311 24.002 21.9196 24.0014 21.6051V2.40041C24.0025 1.76512 23.7483 1.15539 23.2947 0.705265C22.8411 0.255139 22.2251 0.00146202 21.5823 0V0ZM7.70129 18.9909H4.47254V8.72426H7.70129V18.9909ZM6.06667 7.46473C5.04626 7.46473 4.39157 6.75013 4.39157 5.8686C4.38738 4.96637 5.0672 4.2766 6.11134 4.2766C7.15549 4.2766 7.78644 4.96637 7.81017 5.87135C7.80738 6.75013 7.1499 7.46473 6.06667 7.46473ZM19.4912 18.9909H16.2624V13.3002C16.2624 11.9786 15.7948 11.075 14.6264 11.075C13.7344 11.075 13.204 11.6848 12.9695 12.2725C12.8868 12.53 12.8509 12.7999 12.8634 13.0698V18.9909H9.63464V12.0021C9.63464 10.7218 9.59136 9.65684 9.54949 8.72702H12.3539L12.5019 10.1548H12.5661C12.9848 9.48301 14.0318 8.49939 15.7767 8.49939C17.9013 8.49939 19.494 9.90653 19.494 12.9291L19.4912 18.9909Z"
      fill="#110E1E"
    />
  </svg>
)

const FacebookIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 5.3726 18.6274 0 12 0C5.3726 0 0 5.3726 0 12C0 17.9895 4.38822 22.954 10.1249 23.8542V15.4685H7.07809V12H10.1249V9.35631C10.1249 6.34884 11.9165 4.68817 14.6573 4.68817C15.9701 4.68817 17.3434 4.92259 17.3434 4.92259V7.87506H15.8303C14.3395 7.87506 13.8747 8.80003 13.8747 9.74894V12H17.2029L16.6707 15.4687H13.8751V23.8544C19.6126 22.9547 24 17.9896 24 12.0002"
      fill="#110E1E"
    />
  </svg>
)

const TwitterIcon = () => (
  <svg
    width="30"
    height="24"
    viewBox="0 0 30 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.5162 5.97474C26.5342 6.23512 26.5342 6.4955 26.5342 6.75829C26.5342 14.7654 20.4386 24 9.29252 24V23.9952C5.99993 24 2.77573 23.0569 0.00390625 21.2786C0.482676 21.3362 0.963846 21.365 1.44622 21.3662C4.17484 21.3686 6.82548 20.453 8.97214 18.7671C6.3791 18.7179 4.10525 17.0272 3.3109 14.559C4.21924 14.7342 5.15518 14.6982 6.04672 14.4546C3.2197 13.8834 1.18583 11.3996 1.18583 8.51498C1.18583 8.48858 1.18583 8.46338 1.18583 8.43818C2.02818 8.90735 2.97132 9.16774 3.93606 9.19653C1.27343 7.41705 0.452678 3.87487 2.06058 1.10545C5.13718 4.89121 9.6765 7.19266 14.5494 7.43625C14.061 5.33158 14.7282 3.12612 16.3025 1.64661C18.7431 -0.647644 22.5817 -0.530052 24.8759 1.90939C26.233 1.64181 27.5338 1.14384 28.7241 0.438287C28.2717 1.841 27.325 3.03252 26.0603 3.78968C27.2614 3.64808 28.4349 3.3265 29.54 2.83574C28.7265 4.05486 27.7018 5.11679 26.5162 5.97474Z"
      fill="#110E1E"
    />
  </svg>
)

const SocialLinks: React.FC<{ href: string }> = ({ href, children }) => {
  return (
    <a target="_blank" href={href}>
      {children}
    </a>
  )
}

interface Data {
  markdownRemark: {
    html: string
    frontmatter: PostMetadata
  }
}

const Author: React.FC<{
  author: PostMetadata["author"]
  imageSrc: string
}> = ({ author, imageSrc }) => {
  return (
    <div className="flex flex-col space-y-3 items-start">
      <img
        src={imageSrc}
        alt={author.name}
        className="rounded-full border-1 border-black w-20 h-20"
      />

      <p className="text-xl">{author.name}</p>
    </div>
  )
}

const PostInfo: React.FC<{
  author: SummarizedAuthorProps
  date: string
  tags: string[] | null
  postUrl: string
}> = ({ author, date, tags, postUrl }) => {
  return (
    <>
      <Author
        author={author}
        imageSrc={author.authorimage.childImageSharp.original.src}
      />
      <div className="pl-6 border-black border-l-1 sm:border-l-0 sm:pl-0 mt-9">
        <p className="text-base">{date}</p>
        <Tags tags={tags} />
        <hr className="my-5 hidden sm:block border-black" />
        <div className="hidden sm:block">
          <p className="text-sm">SHARE ARTICLE</p>
          <div className="flex w-1/2 mt-5 justify-between">
            <SocialLinks
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${postUrl}`}
            >
              <LinkedInIcon />
            </SocialLinks>
            <SocialLinks
              href={`https://www.facebook.com/sharer/sharer.php?u=${postUrl}`}
            >
              <FacebookIcon />
            </SocialLinks>
            <SocialLinks
              href={`https://twitter.com/intent/tweet?url=${postUrl}`}
            >
              <TwitterIcon />
            </SocialLinks>
          </div>
        </div>
      </div>
    </>
  )
}

const Header: React.FC<{ imageData: IGatsbyImageData; title: string }> = ({
  imageData,
  title,
}) => {
  return (
    <div className="flex flex-col-reverse sm:flex-col">
      <h1 className="font-normal text-3.5xl sm:text-5xl md:text-7xl mb-16">
        {title}
      </h1>
      <GatsbyImage
        objectFit="contain"
        image={imageData}
        alt={title}
        className="border-solid border-0 sm:border-6 border-lc-black mb-16 w-screen sm:w-auto relative -left-6 sm:left-0"
      />
    </div>
  )
}

const Body: React.FC<{
  author: SummarizedAuthorProps
  date: string
  tags: string[] | null
  html: string
  postUrl: string
}> = ({ html, author, date, tags, postUrl }) => {
  return (
    <div className="flex-col-reverse flex sm:flex-row">
      <div
        className="blog-post-content flex-1 font-normal text-lg whitespace-pre-wrap sm:max-w-73/100"
        dangerouslySetInnerHTML={{ __html: html }}
      />
      <div className="flex flex-col sm:w-1/3 sm:pl-8 mb-8 sm:mb-0">
        <PostInfo author={author} date={date} tags={tags} postUrl={postUrl} />
      </div>
    </div>
  )
}

export const BlogTemplate: React.FC<PageProps<Data>> = ({ data }) => {
  const {
    markdownRemark: {
      html,
      frontmatter: {
        slug,
        date,
        title,
        author,
        featuredImage,
        description,
        tags,
      },
    },
  } = data
  const postUrl = `https://livecycle.io/blogs/${slug}`
  return (
    <Layout
      currentSection="blog"
      title={title}
      description={description}
      cardImage={
        featuredImage.childImageSharp.gatsbyImageData.images.fallback.src
      }
    >
      <article className="max-w-6xl p-6 sm:p-15 mx-auto flex flex-col">
        <Header
          imageData={featuredImage.childImageSharp.gatsbyImageData}
          title={title}
        />
        <Body
          html={html}
          author={author}
          date={date}
          tags={tags}
          postUrl={postUrl}
        />
      </article>
    </Layout>
  )
}

export default BlogTemplate

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
        tags
        featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        author {
          name
          authorimage {
            childImageSharp {
              original {
                src
              }
            }
          }
        }
      }
    }
  }
`
